<template>
  <v-container fluid>
    <div class="row row--dense">
      <v-col cols="12" xl="12" lg="12">
        <div class="d-flex justify-space-between flex-wrap" >
          <div class="d-flex bordered qp-tab-nav ">
            <div class="nv_item">
              <v-autocomplete
                  :items="[]"
                  item-value="venue_service_id"
                  item-text="name"
                  outlined
                  multiple
                  placeholder="Services"
                  background-color="rgba(79, 174, 175, 0.1)"
                  hide-details
                  style="max-width: 150px;"
                  class="q-autocomplete shadow-0 nv_item_select q-tab-autocomplete"
                  :height="46"
                  dense
                  color="#4FAEAF"
                  item-color="#4FAEAF"

              >
              </v-autocomplete>
            </div>
            <div class="d-flex p-2">
              <template >
                <div class="nv_item d-flex p-4 pointer" @click="gotoPage('/workshops')">
                  <SvgIcon class="text-xl text-thin-black " text="Academy" >
                    <template v-slot:icon>
                      <DashboardIcon />
                    </template>
                  </SvgIcon>
                </div>
                <v-divider
                    vertical style="background-color: rgba(220, 220, 220, 1)"
                ></v-divider>
              </template>



              <template >
                <div class="nv_item d-flex p-4 pointer" @click="gotoPage('/workshop-sales')">
                  <SvgIcon class="text-xl   qp-tab-nav-is-active " text="Sales" >
                    <template v-slot:icon>
                      <AnalyticsIcon />
                    </template>
                  </SvgIcon>
                </div>

              </template>


            </div>
          </div>

        </div>
      </v-col>
    </div>
    <v-divider class="mt-4" style="border-color: rgba(17, 42, 70, 0.14) !important;"/>

    <v-row>
      <trainer-sales
        :params="params"
        :showProductType="false"
        stackType="service"
        :exportPermission="
          checkExportPermission($modules.trainers.dashboard.slug)
        "
      ></trainer-sales>
    </v-row>
  </v-container>
</template>

<script>
import TrainerSales from "@/components/Chart/OrderSalesGraph";
import DashboardIcon from "@/assets/images/memberships/dashboard-icon.svg";
import AnalyticsIcon from "@/assets/images/memberships/analytics-up.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
export default {
  components: {
    SvgIcon, AnalyticsIcon, DashboardIcon,
    TrainerSales,
  },
  data() {
    return {
      btnShow: false,
      params: { venue_service_ids: [], product_ids: [], product_type_ids: [4] },
    };
  },
  mounted() {

    setTimeout(() => {
      this.btnShow = true;
    }, 10);
  },


  methods:{
    gotoPage(route){
      this.$router.push(route);
    },
  }
};
</script>


<style scoped>
.ptBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}
.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}
.personal-trainer_ic {
  background-image: url("../../assets/images/nav_icon/personal-trainer.png");
  background-position: center center;
  padding: 10px;
  background-size: contain;
}
</style>